import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Closing large contracts quickly`}</h2>
    <p>{`Contrary to what other founders and investors might tell you, it is `}<strong parentName="p">{`definitely possible`}</strong>{` to sign $100k+ contracts in early stages of a startup. The shortest timeframe I've seen from the first conversation to a signed $100k+ contract is 3-4 weeks. More commonly it takes 2-3 months.`}</p>
    <h2>{`Principles behind closing large contracts fast`}</h2>
    <ul>
      <li parentName="ul">{`Companies are more likely to sign contracts if your eventual product will meaningfully impact their core revenue streams.`}</li>
      <li parentName="ul">{`Companies are more likely to sign contracts if they trust you, either through personal connections or because of your on-paper background.`}</li>
      <li parentName="ul">{`The largest companies (eg. big tech) will be least likely to act fast. The challenger companies (eg. $1b-10b valuation companies) will be more likely to make early bets on things that can give them an edge against the big ones. Startups will act fastest but their contracts are worth less. Startups are useful to build momentum to convince challenger companies.`}</li>
      <li parentName="ul"><strong parentName="li">{`Getting a fast no is better than a slow conversation.`}</strong>{` Usually a "no" to a contract really means "not right now", and you can always approach the company again once you've shown more progress.`}</li>
      <li parentName="ul">{`Demonstrating momentum and caliber of team is more critical than defending your technical progress. Any large company signing a contract with an early stage startup knows they are investing now to make the product better over time.`}</li>
    </ul>
    <h2>{`Tactics to close large contracts in 1-2 months`}</h2>
    <ul>
      <li parentName="ul">{`Target companies where your product will impact their core revenue streams. `}<strong parentName="li">{`Tell them a story of how it will help improve their bottom line or solve a core problem they face.`}</strong>{`Don't assume they will put the pieces together, spell it out for them.`}</li>
      <li parentName="ul">{`Target companies where you have strong personal connections or can `}<strong parentName="li">{`get warm intros to senior decision-makers`}</strong>{`. If you're in touch with a junior contact, try to find a warm senior contact in parallel to accelerate the conversation. A strong internal champion with enough authority is critical.`}</li>
      <li parentName="ul">{`Close a couple $1-5k contracts quickly. Use this as social proof to get $10k-50k contracts before pushing the $100k+ contracts to close.`}</li>
      <li parentName="ul">{`Start conversations ASAP with large companies as they will need more time to close.`}</li>
      <li parentName="ul"><strong parentName="li">{`Set deadlines early on in the conversations.`}</strong>{` Ideally, the deadline is within a couple months. Asking for a concrete deadline will force the other party to signal how serious they are about working with you now rather than waiting 6-12 months. Without a deadline, the customer will prioritize work from the 50 other people who are asking them for things by a deadline. The biggest companies will often ignore the deadline (because they know you won't go away), but challengers and startups will treat it seriously. If companies ask to push the deadline out more than 3 months, move on and tell them you can pick up the conversation in a few months.`}</li>
      <li parentName="ul"><strong parentName="li">{`Create scarcity`}</strong>{`. Tell prospective customers you are only taking on 5 pilot partners who will get XXX benefit for being the early partner. This gives challenger companies an extra incentive to act now.`}</li>
      <li parentName="ul">{`Follow up as you approach the deadline with updates on your traction and momentum. `}<strong parentName="li">{`"We received inbound interest from 40 investors and are accelerating our fundraise`}</strong>{`" or "we just closed contracts with X and Y companies and only have 2 pilot spots left" or "we just hit X technical milestone".`}</li>
      <li parentName="ul">{`Make clear how signing a contract will help you close investors and ultimately deliver your product to them faster. If your product is meaningful to your customer, that means `}<strong parentName="li">{`you are both on the same team`}</strong>{`. "Having a signed contract from you will allow us to hire more developers and fast-track our ability to deliver this for you."`}</li>
      <li parentName="ul">{`Offer a 3 month opt-out clause if they are hesitant. You're confident that you're going to make progress, but if they don't get early value they aren't on the hook for the entire amount. "Yeah we are a bit early, that's why we are building in an opt-out clause. This enables you to be one of our pilot partners with lower risk."`}</li>
      <li parentName="ul">{`If they are hesitant, make clear that they won't pay until you start delivering the product. So you can sign the contract now, `}<strong parentName="li">{`but payment will only happen once they onboard onto the service.`}</strong></li>
      <li parentName="ul">{`Offer public-facing confidentiality if they are hesitant. It's most critical that you can share contract info with investors, less important to share it publicly.`}</li>
      <li parentName="ul">{`Demonstrate that you are an expert in the space. Do research on how your technology fits into the broader ecosystem. Demonstrate your expertise when answering their questions in meetings or by sharing an industry outlook or whitepaper with prospective customers.`}</li>
      <li parentName="ul">{`Pitch confidently with strong language. Don't haircut your progress with your own language or get caught up in technicalities. You're talking to business people, not engineers. They are far less nit-picky.`}</li>
      <li parentName="ul">{`Be optimistic. If you don't feel confident that you can close large contracts in a few months, it's definitely not going to happen.`}</li>
      <li parentName="ul">{`Manage your morale through the slow process and the "no's". Sales can feel much more frustrating than engineering since it's less in your control. `}<strong parentName="li">{`If you build a big funnel and pursue it relentlessly, you'll get results.`}</strong></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      